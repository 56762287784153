import React, { memo, useContext } from 'react';
import { above, below } from 'src/components/global/mediaqueries';
import { Configure, Highlight, Hits, Index } from 'react-instantsearch-dom';
import Arrow from 'src/icons/Arrow';
import formatDate from 'src/utils/formatDate';
import Link from 'src/components/Link';
import ProductCard from 'src/components/Search/SearchProductCard';
import PropTypes from 'prop-types';
import SearchContext from 'src/components/Search/Context/Search';
import SearchIndices from 'src/components/Search/SearchIndices';
import SearchResultHeader from 'src/components/Search/SearchResultHeader';
import styled from '@emotion/styled';
import Tag from 'src/components/typography/Tag';
import Text from 'src/components/Text';
import theme from 'src/styles/theme';

const OuterResultWrapper = styled('div')`
    display: ${props => (props.show ? `block` : `none`)};
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;

    .ais-Hits {
        .ais-Hits-list {
            margin: 0px;
            padding: 0px;
            list-style: none;
            margin-left: 0;

            .ais-Hits-item {
                color: ${theme.color.black};
                font-family: 'SuisseIntL';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 16px;

                .ais-Highlight-highlighted {
                    background-color: transparent;
                    text-transform: capitalize;
                    font-weight: 500;
                }

                a {
                    color: ${theme.color.black};
                }
            }
        }
    }
`;

const ResultWrapper = styled('div')`
    ${theme.spacing.mobile._24('margin-top')};
    ${theme.spacing.tablet._40('margin-top')};
    ${theme.spacing.desktop._32('margin-top')};

    ${theme.spacing.mobile._40('margin-bottom')};
    ${theme.spacing.tablet._24('margin-bottom')};
    ${theme.spacing.desktop._32('margin-bottom')};

    color: ${theme.color.black};
    background-color: ${theme.color.white};
    width: 100%;

    ${above.tablet_s} {
        display: grid;
        grid-template-columns: 0.8fr 1.2fr;
        grid-template-rows: 1fr 1fr;
        gap: 0px 0px;
    }

    ${below.tablet_s} {
        position: fixed;
        max-height: 100%;
        top: 0;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 100px;
    }
`;

const SuggestionsWrapper = styled('div')`
    ${theme.spacing.mobile._40('margin-bottom')};
    ${theme.spacing.tablet._0('margin-bottom')};
    ${theme.spacing.desktop._0('margin-bottom')};

    ${theme.spacing.mobile._12('padding-right')};
    ${theme.spacing.tablet._0('padding-right')};
    ${theme.spacing.desktop._0('padding-right')};

    ${theme.spacing.mobile._12('padding-left')};
    ${theme.spacing.tablet._20('padding-left')};
    ${theme.spacing.desktop._32('padding-left')};

    ${below.tablet_s} {
        display: none;

        &.showResultOnHits {
            display: block;
        }
    }

    ${above.tablet_s} {
        grid-area: 1 / 1 / 2 / 2;
    }

    .ais-Hits-item {
        ${theme.spacing.mobile._20('margin-bottom')};
        ${theme.spacing.tablet._20('margin-bottom')};
        ${theme.spacing.desktop._16('margin-bottom')};
    }
`;

const SuggestionsHeader = styled(SearchResultHeader)`
    ${below.tablet_s} {
        border-bottom: none;
    }
`;

const SuggestionsBody = styled('div')`
    ${theme.spacing.mobile._24('padding-top')};
    ${theme.spacing.tablet._24('padding-top')};
    ${theme.spacing.desktop._32('padding-top')};

    ${above.tablet_s} {
        ${theme.spacing.desktop._32('padding-right')};
    }
`;

const ReportsWrapper = styled('div')`
    ${theme.spacing.mobile._40('margin-bottom')};
    ${theme.spacing.tablet._0('margin-bottom')};
    ${theme.spacing.desktop._0('margin-bottom')};

    ${below.tablet_s} {
        display: none;

        &.showResultOnHits {
            display: block;
        }
    }

    ${above.tablet_s} {
        grid-area: 1 / 2 / 3 / 3;
        display: flex;
        flex-direction: column;
        ${theme.spacing.desktop._16('padding-right')};
    }
`;

const ReportHeaderWrapper = styled('div')`
    ${theme.spacing.mobile._12('padding-right')};
    ${theme.spacing.tablet._20('padding-right')};
    ${theme.spacing.desktop._16('padding-right')};

    ${theme.spacing.mobile._12('padding-left')};
    ${theme.spacing.tablet._0('padding-left')};
    ${theme.spacing.desktop._0('padding-left')};

    ${theme.spacing.mobile._24('margin-bottom')};
    ${theme.spacing.tablet._20('margin-bottom')};
    ${theme.spacing.desktop._0('margin-bottom')};
`;

const ResultHeader = styled(SearchResultHeader)`
    ${theme.spacing.tablet._20('padding-left')};
    ${theme.spacing.desktop._32('padding-left')};
`;

const ReportsBody = styled('div')`
    ${theme.spacing.desktop._32('margin-top')};

    ${theme.spacing.tablet._10('padding-left')};
    ${theme.spacing.desktop._16('padding-left')};

    ${theme.spacing.tablet._10('padding-right')};
    ${theme.spacing.desktop._0('padding-right')};

    flex: auto;

    ${above.tablet_s} {
        border-left: 1px solid ${theme.color.black};
    }

    .ais-Hits-list {
        display: flex;

        .ais-Hits-item {
            width: 33.33%;
            border-right: 1px solid ${theme.color.black};

            ${theme.spacing.mobile._12('padding-left')};
            ${theme.spacing.tablet._10('padding-left')};
            ${theme.spacing.desktop._16('padding-left')};

            ${theme.spacing.mobile._8('padding-right')};
            ${theme.spacing.tablet._10('padding-right')};
            ${theme.spacing.desktop._16('padding-right')};

            ${below.desktop_s} {
                width: 50%;
            }

            &:nth-of-type(2) {
                ${below.tablet_s} {
                    ${theme.spacing.mobile._8('padding-left')};
                    ${theme.spacing.mobile._12('padding-right')};
                }

                ${below.desktop_s} {
                    border-right: none;
                }
            }

            &:nth-of-type(3) {
                border-right: none;

                ${below.desktop_s} {
                    display: none;
                }
            }
        }
    }
`;

const NewsWrapper = styled('div')`
    ${theme.spacing.mobile._40('padding-bottom')};
    ${theme.spacing.tablet._0('padding-bottom')};
    ${theme.spacing.desktop._0('padding-bottom')};

    ${theme.spacing.mobile._12('padding-right')};
    ${theme.spacing.tablet._20('padding-right')};
    ${theme.spacing.desktop._32('padding-right')};

    ${theme.spacing.mobile._12('padding-left')};
    ${theme.spacing.tablet._20('padding-left')};
    ${theme.spacing.desktop._32('padding-left')};

    ${below.tablet_s} {
        display: none;

        &.showResultOnHits {
            display: block;
        }
    }

    ${above.tablet_s} {
        grid-area: 2 / 1 / 3 / 2;
    }
`;

const NewsBody = styled('div')`
    ${theme.spacing.mobile._32('padding-top')};
    ${theme.spacing.tablet._24('padding-top')};
    ${theme.spacing.desktop._32('padding-top')};

    .ais-Hits-item:first-of-type {
        ${theme.spacing.mobile._24('padding-bottom')};
        ${theme.spacing.tablet._24('padding-bottom')};
        ${theme.spacing.desktop._32('padding-bottom')};
    }
`;

const NewsPostDate = styled(Tag)`
    ${theme.spacing.mobile._16('margin-bottom')};
    ${theme.spacing.tablet._16('margin-bottom')};
    ${theme.spacing.desktop._16('margin-bottom')};
`;

const NewsPostTextWrapper = styled('div')`
    display: flex;
    flex-direction: row;
`;

const NewsPostText = styled(Text)`
    font-family: 'Tiempos';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.41em;
    width: 100%;

    ${theme.spacing.mobile._24('padding-right')};
    ${theme.spacing.tablet._24('padding-right')};
    ${theme.spacing.desktop._64('padding-right')};
`;

const NewsHit = ({ hit }) => {
    const newsPostDate = formatDate(hit.date);
    const toggleSearch = useContext(SearchContext);
    return (
        <Link src={hit.permalink} onClick={toggleSearch}>
            <NewsPostDate>{newsPostDate}</NewsPostDate>
            <NewsPostTextWrapper>
                <NewsPostText>{hit.title}</NewsPostText>
                <Arrow style={{ marginTop: '4px' }} />
            </NewsPostTextWrapper>
        </Link>
    );
};

const ProductsHit = ({ hit }) => {
    return (
        <ProductCard
            image={{ src: { small: hit?.image?.fluid } }}
            category={hit.categoryName}
            link={hit.link}
            heading={hit.title}
            tag={hit.edition}
        />
    );
};

const SuggestionsHit = ({ hit }) => {
    const toggleSearch = useContext(SearchContext);

    return (
        <Link
            src={`/search?term=${hit.query}`}
            state={{ /* passing empty state to make page re-render */ }}
            onClick={toggleSearch}
        >
            <Highlight attribute="query" hit={hit} tagName="mark" />
        </Link>
    );
};

const SearchResult = ({ query, show, nbHitsSuggestions, nbHitsProducts, nbHitsPosts }) => {

    return (
        <OuterResultWrapper show={show}>
            <ResultWrapper>
                <SuggestionsWrapper className={nbHitsSuggestions > 0 ? 'showResultOnHits' : null}>
                    <Index indexName={SearchIndices[2].name}>
                        <SuggestionsHeader title="Suggestions" />
                        <SuggestionsBody>
                            <Configure hitsPerPage={5} />
                            <Hits hitComponent={SuggestionsHit} />
                        </SuggestionsBody>
                    </Index>
                </SuggestionsWrapper>

                <ReportsWrapper className={nbHitsProducts > 0 ? 'showResultOnHits' : null}>
                    <Index indexName={SearchIndices[1].name}>
                        <ReportHeaderWrapper>
                            <ResultHeader
                                title="Reports"
                                buttonText="View all"
                                quantityText={nbHitsProducts}
                                query={query}
                                category="Products"
                            />
                        </ReportHeaderWrapper>
                        <ReportsBody>
                            <Configure hitsPerPage={3} />
                            <Hits hitComponent={ProductsHit} />
                        </ReportsBody>
                    </Index>
                </ReportsWrapper>

                <NewsWrapper className={nbHitsPosts > 0 ? 'showResultOnHits' : null}>
                    <Index indexName={SearchIndices[0].name}>
                        <SearchResultHeader
                            title="News"
                            buttonText="View all"
                            quantityText={nbHitsPosts}
                            query={query}
                            category="Posts"
                        />
                        <NewsBody className={nbHitsPosts && 'showResultOnHits'}>
                            <Configure hitsPerPage={2} />
                            <Hits hitComponent={NewsHit} />
                        </NewsBody>
                    </Index>
                </NewsWrapper>
            </ResultWrapper>
        </OuterResultWrapper>
    );
};

const hasChanged = (prevProps, nextProps) => prevProps.query !== nextProps.query;

export default memo(SearchResult, hasChanged);

SearchResult.propTypes = {
    nbHitsPosts: PropTypes.number.isRequired,
    nbHitsProducts: PropTypes.number.isRequired,
    nbHitsSuggestions: PropTypes.number.isRequired,
    query: PropTypes.string,
    show: PropTypes.bool.isRequired,
};

SearchResult.defaultProps = {
    query: '',
};

SuggestionsHit.propTypes = {
    hit: PropTypes.object.isRequired,
};

ProductsHit.propTypes = {
    hit: PropTypes.object.isRequired,
};

NewsHit.propTypes = {
    hit: PropTypes.object.isRequired,
};
